<template>
    <SportingClubForm
        base_url="/sportingclub"
        base_path="/sportingclub"
        form_type="new"
        after_store_url="/sportingclub/review/"
    />
</template>

<script>
 import SportingClubForm from "@/view/components/SportingClubForm.vue";
 import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

 export default {
     data() {
         return {
         }
     },

     mounted() {
         this.$store.dispatch(SET_BREADCRUMB, [
             { title: "Affiliazioni", route: "/sportingclub/index" },
             { title: "Nuovo Sodalizio" }
         ]);
     },

     components: {
         SportingClubForm,
     },
 };
</script>
